import { Box, Center, Container, Flex, Heading, Text } from "@chakra-ui/react";
import { isParagraph, renderRule } from "datocms-structured-text-utils";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import ArticleGrid from "src/components/ArticleGrid";
import Layout from "src/components/Layout";
import Pagination from "src/components/Pagination";
import StructuredText from "src/components/StructuredText";
import getExcerpt from "src/lib/getExcerpt";
import getHomePath from "src/lib/getHomePath";

import { PaginationContext } from "../../gatsby/node/lib/PaginationContext";

const Home: FC<PageProps<Gatsby.HomeQuery, PaginationContext>> = ({
  data: { datoCmsHome, datoCmsGlobal },
  pageContext,
}) => {
  if (!datoCmsHome || !datoCmsGlobal) throw Error();

  const allDatoCmsArticle = pageContext.articlesAndArchives;

  return (
    <Layout
      headerInsert={
        <Flex
          align="center"
          direction="column"
          p={{ base: 3, md: 7 }}
          pb={{ base: 12, md: 12 }}
        >
          {datoCmsGlobal.logo?.gatsbyImageData && (
            <Box
              alt=""
              as={GatsbyImage}
              image={datoCmsGlobal.logo.gatsbyImageData}
              mb={6}
            />
          )}

          <Heading
            as="h1"
            color="white"
            mb={6}
            size="xl"
            textAlign="center"
            w="full"
          >
            {datoCmsHome.title}
          </Heading>

          <StructuredText
            customRules={[
              renderRule(isParagraph, ({ children, key }) => (
                <Text color="white" key={key} maxW="750px" textAlign="center">
                  {children}
                </Text>
              )),
            ]}
            data={datoCmsHome.introduction}
          />
        </Flex>
      }
    >
      <Helmet
        meta={[
          {
            name: "description",
            content: getExcerpt(datoCmsHome.introduction, { length: 160 }),
          },
        ]}
        title={datoCmsHome.title}
      />

      <Container maxW="container.xl" pb={10}>
        <ArticleGrid
          articles={allDatoCmsArticle}
          featuredArticle={datoCmsHome.featuredArticle}
          mb={12}
        />

        <Center>
          <Pagination
            currentPage={pageContext.currentPage}
            getPath={getHomePath}
            pageSize={datoCmsGlobal.articlesPerPage!}
            totalItems={pageContext.totalItems}
          />
        </Center>
      </Container>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query Home { # ($skip: Int!, $limit: Int!)
    datoCmsHome {
      title
      introduction {
        value
      }

      featuredArticle {
        ...Article
      }
    }

    # allDatoCmsArticle(
    #   sort: { fields: publicationDate, order: DESC }
    #   skip: $skip
    #   limit: $limit
    # ) {
    #   nodes {
    #     ...Article
    #   }
    # }

    datoCmsGlobal {
      articlesPerPage
      logo {
        gatsbyImageData(width: 88)
      }
    }
  }
`;
